<!-- 关联权限 -->

<template>
    <div class="power-detail-dialog">
        <w-dialog ref="dialogRef" title="关联权限" width="60%" top="6vh" @wConfirm="addConfirm">
            <div class="flex">
                <div class="s-m-r s-m-b">
                    <div class="screen-title">权限分组名称</div>
                    <el-input v-model="currentRow.ng_name" readonly />
                </div>
                <div>
                    <div class="screen-title">所属应用</div>
                    <el-select v-model="m_id" placeholder="请选择权限模块" @change="applicationChange">
                        <el-option :label="item.name" :value="item.id" v-for="item in moduleOption" :key="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <el-scrollbar style="height: 100%" v-loading="isloading">
                <div class="empty" v-if="currentList.length === 0">暂无数据！</div>
                <div class="tab-wp" v-else>
                    <div class="tab-tr">
                        <div class="th">权限一级菜单</div>
                        <div class="th">权限节点</div>
                    </div>
                    <div class="all-check">
                        <el-checkbox v-model="checkAll" @change="allChange">
                            全选/反选
                        </el-checkbox>
                    </div>
                    <div class="tab-tr" v-for="(item, index) in currentList" :key="index">
                        <div class="tab-td">
                            <el-checkbox v-model="item.checked" @change="val => handleCheckAll(val, item)">
                                {{ item.n_name }}
                            </el-checkbox>
                        </div>
                        <div class="tab-td">
                            <el-checkbox v-model="itm.checked" :label="itm.n_id" :key="itm.n_id"
                                v-for="itm in item.child" @change="val => handleChecked(val, item)">
                                {{ itm.n_name }}
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </w-dialog>
    </div>
</template>

<script>
    import { ref, nextTick } from "vue";
    import { SystemManage, } from "@/plugins/api.js";
    import { ElMessage, } from "element-plus";

    export default {
        emits: [],
        components: {},
        setup(props, { emit }) {
            const dialogRef = ref(null);  // 弹框对象
            const moduleOption = ref([]);  // 所属应用选项
            const currentRow = ref('');  // 当前行数据
            const m_id = ref('');  // 所属应用
            const isloading = ref(false);  // 加载状态
            const checkAll = ref(false);  // 是否全选
            const currentList = ref([]);  // 权限列表数据
            /**
             * 
             * 打开弹框
             * 
             * */
            const openDialog = (row) => {
                currentRow.value = row;
                getModuleGroupLists();
                currentList.value = [];
                m_id.value = '';
                dialogRef.value.show();
            };
            /**
             * 
             * 所属应用
             * 
             * */
            const getModuleGroupLists = () => {
                SystemManage.getModuleGroupLists({ ng_id: currentRow.value.ng_id }).then((res) => {
                    if (res.Code === 200) {
                        moduleOption.value = res.Data ? res.Data : [];
                    } else {
                        ElMessage.error(res.Message);
                    }
                });
            }
            /**
             * 
             * 选择所属应用
             * 
             * */
            const applicationChange = () => {
                if (!m_id.value) {
                    currentList.value = [];
                    return;
                }
                getModuleNode();
            }
            /**
             * 
             * 获取权限列表
             * 
             * */
            const getModuleNode = () => {
                isloading.value = true;
                SystemManage.getModuleNode({ ng_id: currentRow.value.ng_id, m_id: m_id.value }).then((res) => {
                    isloading.value = false;
                    if (res.Code === 200) {
                        currentList.value = res.Data ? res.Data : [];
                        currentList.value.forEach(item => {
                            let selectArr = [];
                            // 判断子级是否全选
                            item.child.forEach(son => {
                                if (!son.checked) {
                                    selectArr.push(son);
                                }
                            })
                            // 一级菜单是否全选
                            item.checked = selectArr.length == 0;
                        })
                    } else {
                        ElMessage.error(res.Message);
                    }
                });
            }
            /**
             * 
             * 全选
             * 
             * */
            const allChange = () => {
                currentList.value.forEach(item => {
                    item.checked = checkAll.value;
                    item.child.forEach(itm => {
                        itm.checked = checkAll.value;
                    })
                })
            }
            /**
             * 
             * 一级菜单选择
             * 
             * */
            const handleCheckAll = (val, item) => {
                item.child.forEach(itm => {
                    itm.checked = val;
                })
            }
            /**
             * 
             * 权限节点选择
             * 
             * */
            const handleChecked = (val, item) => {
                let selected = [];
                item.child.forEach(son => {
                    if (!son.checked) {
                        selected.push(son);
                    }
                })
                item.checked = selected.length == 0;
            }
            /**
             * 
             * 关联权限确认保存按钮
             * 
             * */
            const addConfirm = () => {
                let arr = [];
                currentList.value.forEach((item) => {
                    item.child.forEach(son => {
                        if (son.checked) {
                            arr = arr.concat(son.n_id);
                        }
                    })
                });
                if (arr.length == 0) {
                    ElMessage.error("请选择权限");
                    return false;
                }
                dialogRef.value.isLoading = true;
                let params = {
                    ng_id: currentRow.value.ng_id,
                    m_id: m_id.value,
                    m_node: arr.join(","),
                };
                SystemManage.addModuleNodeSave(params).then((res) => {
                    dialogRef.value.isLoading = false;
                    if (res.Code === 200) {
                        dialogRef.value.close();
                        ElMessage.success('关联成功');
                    } else {
                        ElMessage.error(res.Message);
                    }
                });
            }

            return {
                openDialog,
                dialogRef,
                moduleOption,
                currentRow,
                m_id,
                checkAll,
                currentList,
                allChange,
                handleCheckAll,
                handleChecked,
                isloading,
                applicationChange,
                addConfirm,
            };
        },
    };
</script>

<style lang="scss">
    .screen-title {
        font-size: 15px;
        color: var(--text-second-color);
        margin-bottom: 5px;
    }

    .empty {
        margin-top: 50px;
        color: #ccc;
        text-align: center;
    }

    .tab-wp {
        border: 1px solid var(--border-color);
        margin-top: 10px;
        min-height: 500px;

        .tab-tr {
            display: flex;

            .th {
                background-color: var(--search-bg-color);
                line-height: 20px;
                padding: 10px;
                font-size: 15px;
                color: var(--text-third-color);
                border-bottom: 1px solid var(--border-color);
                border-right: 1px solid var(--border-color);
            }

            .th:first-child {
                flex: 1;
                min-width: 200px;
            }

            .th:nth-child(2) {
                flex: 1;
                min-width: 200px;
            }

            .th:last-child {
                flex: 4;
                border-right: none;
            }

            .tab-td {
                background-color: #fff;
                min-height: 30px;
                line-height: 30px;
                padding: 5px 10px;
                font-size: 15px;
                color: #333;
                border-bottom: 1px solid var(--border-color);
                border-right: 1px solid var(--border-color);
                text-align: left;

                .el-checkbox-group {
                    .el-checkbox {
                        vertical-align: middle;
                    }
                }

                .el-checkbox {
                    .el-checkbox__inner {
                        width: 16px;
                        height: 16px;
                    }

                    .el-checkbox__inner::after {
                        top: 2px;
                        left: 5px;
                    }

                    .el-checkbox__label {
                        vertical-align: middle;
                    }
                }
            }

            .tab-td:first-child {
                flex: 1;
                min-width: 200px;
                position: relative;

                .el-checkbox {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .tab-td:last-child {
                flex: 5;
                border-right: none;
            }
        }

        .tab-tr:last-child {
            .tab-td {
                border-bottom: none;
            }
        }

        .all-check {
            border-bottom: 1px solid var(--border-color);
            padding: 10px;
            line-height: 20px;
            box-sizing: border-box;
            text-align: left;

            .el-checkbox {
                .el-checkbox__inner {
                    width: 16px;
                    height: 16px;
                }

                .el-checkbox__inner::after {
                    top: 2px;
                    left: 5px;
                }

                .el-checkbox__label {
                    vertical-align: middle;
                }
            }
        }
    }
</style>