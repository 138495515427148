<!-- 关联人员 -->

<template>
    <div class="power-detail-dialog">
        <w-dialog ref="dialogRef" title="关联权限" width="60%" top="6vh" :hideFooter="true">
            <div class="flex-btw s-m-b">
                <el-select v-model="personStatus" placeholder="请选择人员状态" @change="getNodeGroupUser" clearable>
                    <el-option :label="item.value" :value="item.id" v-for="item in personStatusOption" :key="item.id">
                    </el-option>
                </el-select>

                <el-button type="primary" round @click="onRemoveGroup" :loading="isLoading">移除权限组</el-button>
            </div>

            <el-table ref="tableref" :data="tableDatas" stripe style="width: 100%" element-loading-text="数据加载中"
                tooltip-effect="light" @selection-change="handleSelectionChange" height='450' :row-key="getRowKeys">
                <el-table-column type="selection" width="55" :reserve-selection="true" />
                <el-table-column prop="u_name" label="姓名" show-overflow-tooltip />
                <el-table-column prop="u_tel" label="账号" show-overflow-tooltip />
                <el-table-column prop="u_idcard" label="身份证号" show-overflow-tooltip />
                <el-table-column prop="user_sex_text" label="性别" show-overflow-tooltip />
                <el-table-column prop="user_type_text" label="类型" show-overflow-tooltip />
                <el-table-column prop="user_status_text" label="状态" show-overflow-tooltip />
            </el-table>
        </w-dialog>
    </div>
</template>

<script>
    import { ref, nextTick } from "vue";
    import { SystemManage, } from "@/plugins/api.js";
    import { ElMessage, } from "element-plus";

    export default {
        emits: ["onRemoveGroup"],
        components: {},
        setup(props, { emit }) {
            const dialogRef = ref(null);  // 弹框对象
            const personStatusOption = ref([]);  // 人员状态选项
            const personStatus = ref('');  // 人员状态
            const tableref = ref(null);  // 表格对象
            const tableDatas = ref([]);  // 表格数据
            const multipleSelection = ref([]);  // 选中分组
            const currentRow = ref('');  // 当前行数据
            const isLoading = ref(false);  // 按钮加载状态
            /**
             * 
             * 打开弹框
             * 
             * */
            const openDialog = (row) => {
                currentRow.value = row;
                getUserStatus();
                getNodeGroupUser();
                dialogRef.value.show();
            };
            /**
             * 
             * 状态
             * 
             * */
            const getUserStatus = () => {
                SystemManage.getUserStatus().then((res) => {
                    if (res.Code === 200) {
                        personStatusOption.value = res.Data ? res.Data : [];
                    } else {
                        ElMessage.error(res.Message);
                    }
                });
            }
            /**
             * 
             * 获取表格列表数据
             * 
             * */
            const getNodeGroupUser = () => {
                SystemManage.getNodeGroupUser({ ng_id: currentRow.value.ng_id, status: personStatus.value }).then((res) => {
                    if (res.Code === 200) {
                        tableDatas.value = res.Data ? res.Data.list : [];
                    } else {
                        ElMessage.error(res.Message);
                    }
                });
            }
            const getRowKeys = (row) => {
                return row.id;
            }
            /**
             * 
             * 选择权限分组
             * 
             * */
            const handleSelectionChange = (val) => {
                multipleSelection.value = val;
            }
            /**
             * 
             * 移除按钮
             * 
             * */
            const onRemoveGroup = () => {
                if (multipleSelection.value.length == 0) {
                    ElMessage.error("请选择移除权限组");
                    return false;
                }
                isLoading.value = true;
                let arr = [];
                multipleSelection.value.forEach((item) => {
                    arr = arr.concat(item.u_id);
                });
                let params = {
                    ng_id: currentRow.value.ng_id,
                    u_id: arr.join(","),
                };
                SystemManage.deleteNodeGroupUser(params).then((res) => {
                    if (res.Code === 200) {
                        isLoading.value = false;
                        dialogRef.value.close();
                        ElMessage.success('删除成功');  // 成功提示
                        emit("onRemoveGroup");
                    } else {
                        ElMessage.error(res.Message);
                    }
                });
            }

            return {
                openDialog,
                dialogRef,
                personStatusOption,
                personStatus,
                tableref,
                tableDatas,
                multipleSelection,
                getRowKeys,
                handleSelectionChange,
                currentRow,
                getNodeGroupUser,
                onRemoveGroup,
                isLoading,
            };
        },
    };
</script>

<style lang="scss">

</style>