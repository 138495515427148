<!-- 权限分组 -->

<template>
  <div class="permisso-group main-cnt">
    <div class="content">
      <common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
        :apiName="SystemManage.getPermissionGroupLists" :columns="tableColumns" @onRelatPersonBtn="onRelatPersonBtn"
        @onDeleteBtn="onDeleteBtn" @onAssociaLimitsBtn="onAssociaLimitsBtn" @onEditBtn="onEditBtn">
        <template #operate>
          <el-button type="primary" round @click="onAddGroupBtn">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            添加分组
          </el-button>
        </template>
      </common-table>
    </div>

    <!-- 关联权限 -->
    <AssociaLimits ref="associaLimitsRef"></AssociaLimits>

    <!-- 添加、编辑分组 -->
    <AddGroup ref="addGroupRef" @addConfirm="addGroupConfirm"></AddGroup>

    <!-- 关联人员 -->
    <RelatPerson ref="relatPersonRef" @onRemoveGroup="onRemoveGroup"></RelatPerson>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch } from "vue";
  import { SystemManage } from "@/plugins/api.js";
  import { ElMessage, ElMessageBox } from "element-plus";
  import { useStore } from "vuex";
  import AddGroup from "../components/AddGroup.vue";
  import RelatPerson from "../components/RelatPerson.vue";
  import AssociaLimits from "../components/AssociaLimits.vue";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  const associaLimitsRef = ref(null);  // 关联权限对象
  const addGroupRef = ref(null);  // 添加分组对象
  const relatPersonRef = ref(null);  // 关联人员对象
  /** 表格对象 */
  const chargeTable = ref(null);
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "ng_id",
      label: "ID",
      color: "--text-color",
    }, {
      prop: "ng_name",
      label: "分组名称",
      color: "--text-color",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 360,
      bottons: [
        {
          name: "关联权限",
          action: "onAssociaLimitsBtn",
          token: "members",
        },
        {
          name: "关联人员",
          action: "onRelatPersonBtn",
          token: "members",
        },
        {
          name: "编辑",
          action: "onEditBtn",
          token: "members",
        },
        {
          name: "删除",
          action: "onDeleteBtn",
          token: "members",
        },
      ],
    },
  ]);
  /**
   * 
   * 添加分组按钮
   * 
   * */
  const onAddGroupBtn = () => {
    addGroupRef.value.openDialog();
  }
  /**
  * 
  * 添加分组确认添加按钮
  * 
  * */
  const addGroupConfirm = (data) => {
    let url = data.isAdd ? 'addNodeGroup' : 'editNodeGroup',
      params = {
        ng_name: data.ng_name,
        ng_module: data.ng_module,
        ng_remark: data.ng_remark,
      };
    if (!data.isAdd) {
      // 编辑
      params.ng_id = data.ng_id;
    }
    addGroupRef.value.dialogRef.isLoading = true;
    SystemManage[url](params).then((res) => {
      addGroupRef.value.dialogRef.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success("操作成功！");
        addGroupRef.value.closeDialog();
        chargeTable.value.tableLoad();  // 重新获取列表数据
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
  * 
  * 关联人员按钮
  * 
  * */
  const onRelatPersonBtn = (row) => {
    relatPersonRef.value.openDialog(row);
  }
  /**
  * 
  * 移除权限分组按钮
  * 
  * */
  const onRemoveGroup = () => {
    chargeTable.value.tableLoad();  // 重新获取列表数据
  }
  /**
  * 
  * 编辑按钮
  * 
  * */
  const onEditBtn = (row) => {
    addGroupRef.value.openDialog(row);
  }
  /**
  * 
  * 删除按钮
  * 
  * */
  const onDeleteBtn = (row) => {
    ElMessageBox.confirm(
      `是否删除该权限分组，一经删除不可恢复？`,
      '删除',
      { confirmButtonText: '确认', cancelButtonText: '取消', }
    )
      .then(() => {
        SystemManage.deleteNodeGroup({ ng_id: row.ng_id }).then((res) => {
          if (res.Code === 200) {
            chargeTable.value.tableLoad();  // 重新获取列表数据
            ElMessage.success('删除成功');  // 成功提示
          } else {
            ElMessage.error(res.Message);
          }
        });
      })
      .catch(() => {

      })
  }
  /**
  * 
  * 关联权限按钮
  * 
  * */
  const onAssociaLimitsBtn = (row) => {
    associaLimitsRef.value.openDialog(row);
  }

  onMounted(() => {
    chargeTable.value.tableLoad();
  });
</script>

<style lang="scss">
  .permisso-group {
    font-family: "Source Han Sans CN";

    .content {
      padding: 20px;
    }
  }
</style>