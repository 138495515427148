<!-- 关联、编辑权限 -->

<template>
    <div class="power-detail-dialog">
        <w-dialog ref="dialogRef" :title="groupContent.isAdd ? '关联权限' : '编辑权限'" width="60%" top="6vh"
            :confirmText="groupContent.isAdd ? '确认保存' : '确认编辑'" @wConfirm="addConfirm">
            <div class="cell-30">
                <div class="s-m-b">分组名称</div>
                <el-input v-model="groupContent.ng_name" placeholder="请输入分组名称" clearable></el-input>
            </div>
            <div>
                <div class="associa-title">关联应用</div>
                <div class="flex flex-flow">
                    <div v-for="(item, i) in relatedApplied" :key="i" class="applied-name"
                        :class="item.isSelect ? 'activeStyle' : ''" @click="onApplication(item)">{{ item.name }}</div>
                </div>
            </div>
            <div>
                <div class="associa-title">备注信息</div>
                <el-input v-model.trim="groupContent.ng_remark" type="textarea" rows="3" placeholder="请输入备注信息">
                </el-input>
            </div>
        </w-dialog>
    </div>
</template>

<script>
    import { ref, nextTick } from "vue";
    import { SystemManage, } from "@/plugins/api.js";
    import { ElMessage, } from "element-plus";

    export default {
        emits: ["addConfirm"],
        components: {},
        setup(props, { emit }) {
            const dialogRef = ref(null);  // 弹框对象
            const groupContent = ref({
                ng_name: '',  // 姓名
                ng_module: '',  // 模块id
                ng_remark: '',  // 备注
                isAdd: true,  // 是否新增
            });
            const relatedApplied = ref([]);  // 关联应用
            const currentRow = ref('');  // 当前行数据
            /**
             * 
             * 打开弹框
             * 
             * */
            const openDialog = (row) => {
                currentRow.value = row ? row : '';
                if (!currentRow.value) {
                    groupContent.value = {
                        ng_name: '',  // 姓名
                        ng_module: '',  // 模块id
                        ng_remark: '',  // 备注
                        isAdd: true,  // 是否新增
                    };
                } else {
                    groupContent.value.ng_name = row.ng_name;
                    groupContent.value.ng_remark = row.ng_remark;
                    groupContent.value.ng_id = row.ng_id;
                    groupContent.value.isAdd = false;
                }
                getModuleLists();
                dialogRef.value.show();
            };
            /**
             * 
             * 关闭弹框
             * 
             * */
            const closeDialog = () => {
                dialogRef.value.close();
            };
            /**
            * 
            * 获取关联应用
            * 
            * */
            const getModuleLists = () => {
                SystemManage.getModuleLists().then((res) => {
                    if (res.Code === 200) {
                        relatedApplied.value = res.Data ? res.Data : [];
                        relatedApplied.value.forEach(item => {
                            if (groupContent.value.isAdd) {
                                // 新增
                                item.isSelect = false;
                            } else {
                                // 编辑回显关联应用
                                currentRow.value.ng_module.forEach(mod => {
                                    item.isSelect = mod == item.id;
                                })
                            }
                        })
                    } else {
                        ElMessage.error(res.Message);
                    }
                });
            }
            /**
             * 
             * 点击关联应用
             * 
             * */
            const onApplication = (item) => {
                item.isSelect = !item.isSelect;
            }
            /**
             * 
             * 确认新增、编辑按钮
             * 
             * */
            const addConfirm = () => {
                let applicArr = [];
                if (relatedApplied.value.length > 0) {
                    relatedApplied.value.forEach(item => {
                        if (item.isSelect) {
                            applicArr.push(item.id);
                        }
                    })
                }
                if (!groupContent.value.ng_name) {
                    ElMessage.error("请输入分组名称");
                    return false;
                } else if (applicArr.length == 0) {
                    ElMessage.error("请选择关联应用");
                    return false;
                }
                groupContent.value.ng_module = applicArr.join(",");
                emit("addConfirm", groupContent.value);
            }

            return {
                openDialog,
                dialogRef,
                addConfirm,
                relatedApplied,
                onApplication,
                groupContent,
                closeDialog,
                currentRow,
            };
        },
    };
</script>

<style lang="scss">
    .applied-name {
        padding: 5px 16px;
        background: var(--search-uncheck-bg-color);
        border-radius: 4px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 14px;
    }

    .associa-title {
        margin: 20px 0 10px 0;
        font-weight: bold;
    }

    .activeStyle {
        background: var(--menu-bg-color);
        color: var(--text-white-color);
    }

    .el-textarea__inner {
        background-color: var(--search-uncheck-bg-color);
        border: none;
        color: var(--text-color);
    }
</style>